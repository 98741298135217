var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};
var site = site || {};

(function ($) {
  $(document).on('inv_status_data:updated', '.js-product', function (e) {
    var $statusList = $('.js-inv-status-list', $(this));
    var skuBaseId = $statusList.data('sku-base-id');
    var skuData = prodcat.data.getSku(skuBaseId);

    if (!!skuData) {
      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData);
    }
  });

  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
    var $statusList = $('.js-inv-status-list', $(this));

    if ($statusList.length < 1) {
      return null;
    }
    Drupal.behaviors.inventoryStatusV1.selectSku($statusList, skuBaseId);
  });

  $(document).on('product.init', '.js-product', function (e) {
    var $statusList = $('.js-inv-status-list', $(this));
    var skuBaseId = $statusList.data('sku-base-id');
    var skuData = prodcat.data.getSku(skuBaseId);

    if (!!skuData) {
      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData);
    }
    if (Drupal.settings.common.enable_preorder) {
      var $productCta = $('.js-product-cta', $(this));
      var dataSkuBaseId = $productCta.data('sku-base-id');
      var dataSku = prodcat.data.getSku(dataSkuBaseId);

      if (dataSku) {
        Drupal.behaviors.inventoryStatusV1.isPreorder(dataSku);
      }
    }
  });

  Drupal.behaviors.inventoryStatusV1 = {
    updateCtaCopy: function (skuData) {
      var prodId = skuData.PRODUCT_ID;
      var $context = $('.js-product[data-product-id="' + prodId + '"]');
      var $addToBagBtn = $('.js-add-to-cart[data-product-id = "' + prodId + '"]', $context);
      var soldOutCopy = site.translations.product.product_cta_sold_out;
      var tempOutOfStockCopy = site.translations.product.product_cta_temp_out_of_stock;

      if (
        !!skuData.INVENTORY_STATUS &&
        !!soldOutCopy &&
        !!tempOutOfStockCopy &&
        Drupal.settings.common.enable_oos_cta_update
      ) {
        switch (skuData.INVENTORY_STATUS) {
          case 2:
            $addToBagBtn.text(tempOutOfStockCopy).addClass('button-disabled');
            break;
          case 7:
            $addToBagBtn.text(soldOutCopy).addClass('button-disabled');
            break;
          default:
            $addToBagBtn
              .text(site.translations.product.product_cta_add_to_bag)
              .removeClass('button-disabled');
        }
      }
    },

    update: function ($statusList, skuData) {
      var skuBaseId = $statusList.data('sku-base-id');

      if (!skuData || !skuData.INVENTORY_STATUS) {
        return null;
      }
      if (skuData.SKU_ID !== 'SKU' + skuBaseId) {
        return null;
      }
      $('li', $statusList).hide();
      var $statusToShow = $('.js-inv-status-' + skuData.INVENTORY_STATUS, $statusList);

      if ($statusToShow.length > 0) {
        $statusToShow.show();
        $statusList.trigger('inv_status_display:updated');
      }
      this.updateCtaCopy(skuData);
    },

    isPreorder: function (skuData) {
      var prodId = skuData.PRODUCT_ID;
      var $context = $('.js-product[data-product-id="' + prodId + '"]');
      var $addToBagBtn = $('.js-add-to-cart[data-product-id = "' + prodId + '"]', $context);
      var $statusList = $('.js-inv-status-list[data-product-id = "' + prodId + '"]', $context);
      var $activePreOrderStatus = $('.js-active-text', $context);
      var $tosPreOrderStatus = $('.js-tos-text', $context);
      var $soldOutPreOrderStatus = $('.js-sold-text', $context);
      var $preOrderStatus = $('.js-preorder-text', $context);
      var $productPreOrder = $('.js-product-preorder', $context);

      $addToBagBtn.removeClass('preorder-disabled');
      $preOrderStatus.addClass('hidden');

      if (skuData.isPreOrder === 1 && skuData.INVENTORY_STATUS !== 3) {
        $statusList.addClass('hidden');
        switch (skuData.INVENTORY_STATUS) {
          case 1:
            var activeMsg = $activePreOrderStatus
              .html()
              .replace(/::rs_dt::/, skuData.formattedPreOrderShippingDate);

            $activePreOrderStatus.html(activeMsg).removeClass('hidden');
            $addToBagBtn
              .text(site.translations.product.pre_order_text)
              .removeClass('preorder-disabled');
            break;
          case 2:
            var tosMsg = $tosPreOrderStatus
              .html()
              .replace(/::rs_dt::/, skuData.formattedPreOrderReleaseDate);

            $tosPreOrderStatus.html(tosMsg).removeClass('hidden');
            $addToBagBtn
              .text(site.translations.product.pre_order_text)
              .addClass('preorder-disabled');
            break;
          case 7:
            var soldMsg = $soldOutPreOrderStatus
              .html()
              .replace(/::rs_dt::/, skuData.formattedPreOrderReleaseDate);

            $soldOutPreOrderStatus.html(soldMsg).removeClass('hidden');
            $addToBagBtn
              .text(site.translations.product.pre_order_text)
              .addClass('preorder-disabled');
            break;
        }
      } else {
        $addToBagBtn.text(site.translations.product.product_cta_add_to_bag);
        $statusList.removeClass('hidden');
      }

      if ($preOrderStatus.length === $('.hidden', $productPreOrder).length) {
        $productPreOrder.addClass('hidden');
      }
    },

    selectSku: function ($statusList, skuBaseId) {
      $statusList.data('sku-base-id', skuBaseId);
      var skuData = prodcat.data.getSku(skuBaseId);

      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData);
      if (Drupal.settings.common.enable_preorder) {
        Drupal.behaviors.inventoryStatusV1.isPreorder(skuData);
      }
    }
  };
})(jQuery);
